@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #232F3C;
  font-family: Arial, sans-serif;
}
