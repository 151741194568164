/*
NEWS
 */

#news .videoplayer,
#news video {
  border-radius: 6px;
}

#news .videoplayer .controls {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/*
SEARCH
 */

.search-input-form-field {
  mat-icon.search-icon {
    //position: relative;
    //top: -2px;
  }
}

/*
EXPANSION-PANEL
 */

.mat-expansion-panel-header-description {
  font-size: 12px;
}

.mat-expansion-panel-content {
  padding: 0px 26px;
}

/*
OVERLAY
 */

.mat-dialog-container {
  position: relative;
  background: rgba(45, 59, 76, 0.8);
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

.big-picture {
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      width: calc(100% - 10px);
      height: calc(100% - 10px);

      .mat-dialog-container {
        padding: 0px;

        app-laden-dialog {
          pointer-events: none;

          .close-button,
          .checkliste-button,
          .big-picture-image {
            pointer-events: auto;
          }

          div.big-picture-image {
            display: block;
          }

          .mat-dialog-content {
            .content {
              div.image-container {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

/*
MAT-VIDEO
 */

mat-video-spinner {
  display: none;
}

/*
CHECKLISTE
 */

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3) !important;
}

.mat-select-arrow {
  color: rgba(255, 255, 255, 0.7) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #CCCCCC;
}

.mat-form-field-label {
  color: rgba(255, 255, 255, 0.7) !important;
}

/*
PERFECT SCROLLBAR
*/

.ps__thumb-y {
  background-color: rgba(62, 129, 202, 0.9) !important;
}

.search-keywords,
.mat-dialog-content .text {
  .ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: rgba(240, 240, 240, 0.2) !important;
  }

  perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y, perfect-scrollbar > .ps.ps--scrolling-x > .ps__rail-x {
    background-color: rgba(240, 240, 240, 0.1) !important;
  }
}
